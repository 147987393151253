import { Container, Grid, FormControlLabel, Checkbox } from '@mui/material'
import React, { useState } from 'react'
import { TextCurrencyField } from './TextCurrencyField'
// import { TextPercentageField } from './TextPercentageField'
import { TextFieldInput } from './TextField'
// import { ThirdPartyPayOff } from './ThirdPartyPayOff'
// import { periodicFrequencyValue } from '../utils/helperFunctions'

export const CNDisclosure = ({ values, errors, touched, formik }) => {
  return (
    <>
      <h1 style={{ marginLeft: '3%' }}>Connecticut Disclosure</h1>
      <Container maxWidth="xl">
        <Grid item xs={12} container spacing={3}>
          <TextCurrencyField
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            disabled={true}
            value={values.purchasePricePaidToSeller}
            name="CnFA"
            heading="Funding Amount"
          />
          <TextFieldInput
            name="Cnfruquency"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Frequency"
            disabled={true}
          />

          {/* new */}
          <TextCurrencyField
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            disabled={true}
            value={
              +parseFloat(
                +formik?.current?.values?.originationFee +
                  +formik?.current?.values?.wireFee +
                  +formik?.current?.values?.processingFee
              ).toFixed(2)
            }
            name="CnFCD"
            heading="Finance Charges Deducted"
          />
          <TextCurrencyField
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            disabled={true}
            value={
              +parseFloat(
                +formik?.current?.values?.purchasePricePaidToSeller -
                  (+formik?.current?.values?.originationFee +
                    +formik?.current?.values?.wireFee +
                    +formik?.current?.values?.processingFee)
              ).toFixed(2)
            }
            name="CnDA"
            onChange={(e) => {
              e.preventDefault()
              formik.current.setFieldValue('CnDA', e.target.value)
            }}
            heading="Disbursement Amount"
          />

          <TextFieldInput
            name="CnTM"
            error={errors.CnTM}
            touched={touched.CnTM}
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
            }}
            type={'number'}
            heading="Terms in Month"
            onChange={(e) => {
              const { value } = e.target
              formik.current.setFieldValue('CnTM', value)
              formik.current.setFieldValue('CnET', value ? value * 30 : 0)
              let first =
                (+formik?.current?.values?.purchasedAmmountofFutureReceipts || 0) -
                (+formik?.current?.values?.purchasePricePaidToSeller || 0) +
                (+formik?.current?.values?.wireFee || 0) +
                (+formik?.current?.values?.originationFee || 0) +
                (+formik?.current?.values?.processingFee || 0)
              let second = (+formik?.current?.values?.administrativeFee || 0) * (+value || 0)
              let third = +first + +second
              let Newfirst =
                (+formik?.current?.values?.purchasedAmmountofFutureReceipts || 0) -
                (+formik?.current?.values?.purchasePricePaidToSeller || 0)
              formik.current.setFieldValue('CnFC', +Newfirst.toFixed(2))
              formik.current.setFieldValue('CnPrepayment', +third.toFixed(2))
            }}
          />
          {/* Payback field is the same and Purchase Amount Field we already capture */}
          <TextFieldInput
            name="CnFC"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Finance charge(s)"
            disabled={true}
          />
          <TextFieldInput
            name="CnTPA"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            value={
              +parseFloat(
                (+formik?.current?.values?.purchasedAmmountofFutureReceipts +
                  +formik?.current?.values?.administrativeFee) *
                  +formik?.current?.values?.CaTM
              ).toFixed(2)
            }
            onChange={(e) => {
              e.preventDefault()
              formik.current.setFieldValue('CnTPA', e.target.value)
            }}
            type="number"
            heading="Estimated Total Payment Amount"
            disabled={true}
          />

          <TextCurrencyField
            name="CnIB"
            error={errors.CnIB}
            touched={touched.CnIB}
            heading="Internal Balance"
            allowFloat={true}
            onChange={(e) => {
              e.preventDefault()
              const numericValue = e.target.value.replace(/[^0-9.]/g, '')
              if (/^\d*\.?\d*$/.test(numericValue) || numericValue === '') {
                // Set field value directly without relying on Formik's onChange
                formik.current.setFieldValue('CnIB', numericValue)
              } else {
                formik.current.setFieldError('CnIB', 'Please enter a valid number')
              }
            }}
          />
          <TextFieldInput
            name="CnET"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Estimated Term"
            disabled={true}
          />
          <TextFieldInput
            name="CnETP"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Estimated Time Period "
            onChange={(e) => {
              e.preventDefault()
              formik.current.setFieldValue('CnETP', e.target.value)
            }}
          />

          {formik?.current?.values?.priorBalance > 0 && (
            <>
              <TextFieldInput
                name="CnPBFC"
                // error={errors.CnTM}
                // touched={touched.CnTM}
                spanStyle={{
                  float: 'left',
                  fontWeight: 'bold',
                }}
                type={'number'}
                heading="Prior Balance Finance Charge"
                onChange={(e) => {
                  const { value } = e.target
                  formik.current.setFieldValue('CnPBFC', value)
                }}
              />
              <TextCurrencyField
                name="CnPPA"
                spanStyle={{
                  float: 'left',
                  fontWeight: 'bold',
                }}
                type={'number'}
                heading="Payback of Prior Account"
                onChange={(e) => {
                  const { value } = e.target
                  formik.current.setFieldValue('CnPPA', value)
                }}
              />
              <TextCurrencyField
                name="discount"
                spanStyle={{
                  float: 'left',
                  fontWeight: 'bold',
                  fontSize: '120%',
                }}
                onChange={(e) => {
                  e.preventDefault()
                  formik.current.setFieldValue('discount', e.target.value)
                  const PBFC = +formik?.current?.values?.CnPBFC || 0
                  const pendingBalance = +formik?.current?.values?.priorBalance || 0
                  const PPA = +formik?.current?.values?.CnPPA || 1
                  const discount = +formik?.current?.values?.discount || 0

                  const first = pendingBalance / PPA
                  const second = PBFC * first
                  const third = second - discount
                  formik.current.setFieldValue('CnDFC', +third.toFixed(2))
                }}
                type={'number'}
                disabled={false}
                heading="Discount"
              />
              <TextCurrencyField
                spanStyle={{
                  float: 'left',
                  fontWeight: 'bold',
                  fontSize: '120%',
                }}
                disabled={true}
                name="CnDFC"
                heading="DISCOUNTED FINANCE CHARGE"
              />
            </>
          )}
          <TextFieldInput
            name="CnCA"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            onChange={(e) => {
              e.preventDefault()
              formik.current.setFieldValue('CnCA', e.target.value)
            }}
            heading="Commission Amount"
          />
          <TextFieldInput
            name="CnMSP"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            onChange={(e) => {
              e.preventDefault()
              formik.current.setFieldValue('CnMSP', e.target.value)
            }}
            heading="Monthly Specified Percentage"
          />
          <TextFieldInput
            name="CnPCPP"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            onChange={(e) => {
              e.preventDefault()
              formik.current.setFieldValue('CnPCPP', e.target.value)
            }}
            heading="Payment Charges Payable to provider"
          />
        </Grid>
        {/* <ThirdPartyPayOff disclosure="Cn" errors={errors} touched={touched} formik={formik} /> */}
      </Container>
    </>
  )
}
