import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Sidebar, Menu, MenuItem, sidebarClasses } from 'react-pro-sidebar'
import SpeedIcon from '@mui/icons-material/Speed'
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency'
import ArticleIcon from '@mui/icons-material/Article'
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu'

const CustomSidebar = () => {
  const [toggled, setToggled] = React.useState(false)
  const [broken, setBroken] = React.useState(false)
  const userRole = localStorage.getItem('role')
  
  return (
    <>
      {broken && (
        <div style={{ marginLeft: '2rem', marginRight: '2rem' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <MenuIcon onClick={() => setToggled(!toggled)} />
            <span>Menu</span>
          </div>
        </div>
      )}

      <Sidebar
        onBackdropClick={() => {
          setToggled(false)
        }}
        toggled={toggled}
        closeOnClick={true}
        onBreakPoint={setBroken}
        breakPoint="1248px"
        rootStyles={{
          [`.${sidebarClasses.container}`]: {
            backgroundColor: 'white',
          },
        }}
      >
        <Menu
          menuItemStyles={{
            backgroundColor: 'white',
            border: 'none',
            button: {},
          }}
        >
          <div style={{ marginLeft: '7%' }}>
            <MenuItem component={<Link to="/" />}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <SpeedIcon />
                <span style={{ marginLeft: '3%' }}>Dashboard</span>
              </div>
            </MenuItem>

            {userRole === 'admin' && (
              <MenuItem component={<Link to="/users" />}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <ContactEmergencyIcon />
                  <span style={{ marginLeft: '3%' }}>Users</span>
                </div>
              </MenuItem>
            )}
            <MenuItem component={<Link to="/qtm-contract" />}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <ArticleIcon />
                <span style={{ marginLeft: '3%' }}>Advance Agreement</span>
              </div>
            </MenuItem>
            <MenuItem component={<Link to="/dynamic-contract" />}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <ArticleIcon />
                <span style={{ marginLeft: '3%' }}>Loan Agreement</span>
              </div>
            </MenuItem>
            {/* <MenuItem component={<Link to="/dynamic-loan-contract" />}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <ArticleIcon />
                <span style={{ marginLeft: '3%' }}>New Loan Agreement</span>
              </div>
            </MenuItem> */}
          </div>
        </Menu>
      </Sidebar>
    </>
  )
}

export default CustomSidebar
