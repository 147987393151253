import Users from '../pages/Users'
import Layout from '../layout/Layout'
import NotFound from '../pages/NotFound'
import { LoginPage } from '../auth/LoginPage'
import ProtectedRoute from './ProtectedRoute'
import ManagerLogin from '../auth/managerLogin'
import { ResetPassword } from '../auth/ResetPassword'
import { ForgotPassword } from '../auth/ForgotPassword'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { CreateContract } from '../pages/CreateContract'
import { DynamicContract2 } from '../pages/DynamicContract2'
import { LoanContract } from '../pages/LoanContract'
import ContractList from '../pages/ContractList'
import Profile from "../pages/Profile";

export const MainRoutes = () => {
  return (
    <div>
      <Router>
        <Layout>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/manager" element={<ManagerLogin />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <ContractList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users"
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route path="/qtm-contract/:id?" element={<CreateContract />} />
            <Route path="/dynamic-contract/:id?" element={<DynamicContract2 />} />
            <Route path="/dynamic-loan-contract/:id?" element={<LoanContract />} />
              <Route
                  path="/profile"
                  element={
                      <ProtectedRoute>
                          <Profile />
                      </ProtectedRoute>
                  }
                  />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </Router>
    </div>
  )
}
